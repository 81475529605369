import React, { Component } from "react"
import Layout from "../components/Layout/layout"
import { graphql } from 'gatsby'

class AboutPage extends Component {
  render() {
    let aboutData = this.props.data.allAboutJson.nodes[0]
    let teamMembers = []
    let i = 0
    aboutData.team_members.forEach(item => {
      teamMembers.push(
        <div key={i} className="team-grid-container">
          <div className="team-grid-item-left">
          <img src={item.image} alt="Chris Lenaerts" className="team-icon"></img>
          </div>
          <div className="team-grid-item-right">
         
          </div>
        </div>
      )
      i++
    })

    return (
      <Layout page={"about"}>
        <div className="main">
          <h1 className="title">{aboutData.title}</h1>
          <div class="pl-8">{teamMembers}</div>
          <div className="text">{aboutData.introtext}</div>
          <div className="text">{aboutData.text}</div>
          <div><h2 className="subtitle">{aboutData.subtitle}</h2></div>
          
          <div className="divider"></div>
          
          <section class="text-gray-600 body-font">
		  	<div class="container px-5 py-2 mx-auto flex flex-wrap">
		  		<div class="lg:w-2/3 mx-auto">
		  			<div class="flex flex-wrap w-full bg-gray-100 py-1 px-2 relative mb-1">
		  				<div class="h-full bg-gray-100 bg-opacity-75 px-2 pt-2 pb-4 rounded-lg overflow-hidden text-center relative">
		  					<div className="text">{aboutData.ecommercedescription}</div>
		  				</div>
		  			</div>
		  		</div>
		  	</div>
		  	</section>
        </div>
      </Layout>
    )
  }
}
export default AboutPage

export const query = graphql`
  query {
    allAboutJson {
      nodes {
        id
        title
        description
        introtext
        ecommercedescription
        text
        subtitle
        name_icon
        title_icon
        mail_icon
        twitter_icon
        team_members {
          name
          title
          email
          twitter
          image
        }
      }
    }
  }
`
